<template>
	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" @closed="onclosed" :close-on-click-modal="false"  :close-on-press-escape="false">
			<div class="form_box">

				<div class="form_flex" v-for="(group, gindex) in filter_groupList" :key="gindex">
					<div class="lefts">
						{{gindex +1}}级组织架构
					</div>
					<div class="rights">
						<el-select disabled v-model="groupSelectList[gindex]" placeholder="请选择">
							<el-option v-for="item in group" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						岗位名称
					</div>
					<div class="rights">
						<el-input clearable v-model="form.title" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						岗位编码
					</div>
					<div class="rights">
						<el-input clearable v-model="form.sn" placeholder="请输入"></el-input>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						定员人数
					</div>
					<div class="rights">
						<el-input clearable v-model="form.num" placeholder="请输入"></el-input>
					</div>
				</div>

				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {
			// groupList: {
			// 	type: Array,
			// 	default: () => {
			// 		return []
			// 	}
			// }
		},
		data() {
			return {
				modal_title: '新增岗位',
				shows: false,
				form: {
					is_gw: 1,
					id: 0,
					parent_id: 0,
					title: '',
					sn: '',
					sort: '',
					level: '',
					num: '',
				},
				//


				group_list_select: [],
				deep: 0,

				groupList: [],
				groupSelectList: [],
			};
		},

		computed: {
			filter_groupList() {
				let arr = []
				if (this.groupList.length) {
					arr = this.groupList.slice(0, this.deep - 1)
				}

				return arr
			}
		},

		watch: {

		},

		created() {

		},

		methods: {
			onclosed() {
				this.form = {
					is_gw: 1,
					id: 0,
					parent_id: 0,
					title: '',
					sn: '',
					sort: '',
					level: '',
					num: '',
				}
			},

			init(option) {
				this.$log('init option', option)
				let {
					deep,
					groupSelectList,
					groupList
				} = option;

				this.deep = deep;
				this.groupSelectList = JSON.parse(JSON.stringify(groupSelectList))
				this.groupList = JSON.parse(JSON.stringify(groupList))
				//


				console.log('部门层级deep', deep)
				this.shows = true
				let row = option.row;
				if (row) {
					this.modal_title = '编辑岗位'
					this.form = {
						is_gw: 1,
						id: row.id,
						parent_id: row.parent_id,
						title: row.title,
						sn: row.sn,
						sort: row.sort,
						level: row.level,
						num: row.num,
					}
				} else {
					this.modal_title = '新增岗位'
				}
			},


			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				console.log('filter_groupList',this.filter_groupList)
				console.log('groupSelectList',this.groupSelectList)
				if (!this.form.title) {
					alertErr('请输入岗位名称')
					return
				}
				if (!this.form.sn) {
					alertErr('请输入岗位编号')
					return
				}
				if (!this.form.title) {
					alertErr('请输入定员人数')
					return
				}
				let depart_path_id=[]
				let depart_title=[]
				this.filter_groupList.forEach((e,i)=>{
					e.forEach((v,vi)=>{
						if(v.id==this.groupSelectList[i]){
							console.log(e)
							let data=v
							depart_path_id.push(data.id)
							depart_title.push(data.title)
						}
					})
				})

				let params = {
					...this.form,
					level: this.deep,
					parent_id: this.groupSelectList[this.groupSelectList.length - 1],
					depart_path_id:depart_path_id.join('/'),
					depart_title:depart_title.join('/'),
				}
				console.log('要提交的表单数据', params)

				// return
				this.$api("editCategory", params, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.shows = false
						this.$emit("confirm");
					}
				});
			},

			handleUserSearch(value) {
				console.log('处理用户输入', value)

				this.$api("queryUser", {
					name: value
				}, "get").then((res) => {
					if (res.code == 200) {
						this.user_list = res.data || []
					} else {

					}
				});

			}
		},



	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts {
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 40px;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

							.el-input__inner {

							// background: #F2F5FA;

							// border: 0;

						}
					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>